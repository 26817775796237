import React from 'react';
import Card from '@components/Card';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';

import * as styles from './SupportCategories.module.scss';

export type SupportCategory = {
  name: string;
  description: string;
  href: string;
  id?: string;
};

type SupportCategoriesProps = React.HTMLProps<HTMLDivElement>;

const supportCategories = [
  {
    name: 'Kort',
    description: 'Läs mer om Rockerkortet',
    href: 'https://support.rocker.com/hc/sv/categories/16868125697041-Kort',
  },
  {
    name: 'Paket',
    description: 'Läs mer om Rockerpaketen',
    href: 'https://support.rocker.com/hc/sv/categories/28830270062993-V%C3%A5ra-paket',
  },
  {
    name: 'Buffert',
    description: 'Läs mer om Rocker Buffert',
    href: 'https://support.rocker.com/hc/sv/categories/28830102410385-Buffert',
  },
  {
    name: 'Spara',
    description: 'Läs mer om sparkonton',
    href: 'https://support.rocker.com/hc/sv/categories/28829980113553-Sparande',
  },
  {
    name: 'Connect',
    description: 'Läs mer om Rocker Connect',
    href: 'https://support.rocker.com/hc/sv/categories/28697674805905-Connect-Ordna',
  },
  {
    name: 'Cashback',
    description: 'Läs mer om cashback',
    href: 'https://support.rocker.com/hc/sv/categories/28830643283985-Cashback',
  },
  {
    name: 'Rocker Pay',
    description: 'Läs mer om Rocker Pay',
    href: 'https://support.rocker.com/hc/sv/categories/28701523804689-Rocker-Pay',
  },
  {
    name: 'Privatlån',
    description: 'Läs mer om privatlån',
    href: 'https://support.rocker.com/hc/sv/categories/28905499841553-Privatl%C3%A5n',
  }
];

function SupportCategories({ ...rest }: SupportCategoriesProps) {
  return (
    <div className={styles.supportCategories} {...rest}>
      <div className={styles.grid}>
        {supportCategories.map(category => (
          <Card className={styles.card} key={category.href} small theme="white">
            <h2>{category.name}</h2>
            <p>{category.description}</p>
            <a href={category.href} id={category.href} target="_blank" rel="noreferrer" className={styles.link}>
              Frågor och svar
            </a>
          </Card>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <PrimaryButton href="https://support.rocker.com/hc/sv">Alla frågor och svar</PrimaryButton>
      </div>
    </div>
  );
}

export default SupportCategories;
