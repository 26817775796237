import React, { useEffect, useState } from 'react';
import * as styles from './ecbRates.module.css';
import currencies from './currencies.json';
import SelectInput from '../loan-application-components/selectInput';

interface ECBRates {
  fromMoney: FromMoney;
  toMoneyWithVisaRate: FromMoney;
  toMoneyWithMarkup: FromMoney;
  toMoneyWithEcbRate: FromMoney;
  fxRateVisa: number;
  fxRateWithMarkup: number;
  fxRateEcb: number;
  markupPercentageOverEcb: number;
}

interface FromMoney {
  currency: string;
  amount: number;
  unit: string;
}

const EcbRates = () => {
  const [ecbRate, setEcbRate] = useState<ECBRates>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currency, setCurrency] = useState(
    currencies.currencies.find(x => x.alpha3 === 'EUR'),
  );
  const isProduction =
    typeof window !== 'undefined' &&
    'https://rocker.com' === window.location.origin;

  const baseUrl = isProduction
    ? 'https://card.rockerapi.dev'
    : 'https://card-test.rockerapi.dev';

  useEffect(() => {
    fetchData(currency.alpha3);
  }, []);

  useEffect(() => {
    fetchData(currency.alpha3);
  }, [currency]);

  const fetchData = async param => {
    try {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const auth = isProduction
        ? `Basic dmlzYV9meF9yYXRlX2FwaV91c2VyX3Byb2Q6UlN3WFZWQjJ4dDhScXpwSFM0bXd0Vkhjc3ZFZTVLMjltNGR5VkJwSA==`
        : `Basic dmlzYV9meF9yYXRlX2FwaV91c2VyX3Rlc3Q6N21xNEttWFdjcURaUXhUdmVXTVBxSnluUWN1c2tk`;
      headers.append('Authorization', auth);
      const response = await fetch(
        `${baseUrl}/visa-fx-exchange-rate?fromAmount=100&fromCurrency=SEK&toCurrency=${param}`,
        {
          headers,
        },
      );
      const data = await response.json();
      setEcbRate(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={`container ${styles.container}`}>
        <h2>Rockers Visa-kort, växlingskurser</h2>
      </div>
    );
  }
  if (error) {
    return (
      <div className={`container ${styles.container}`}>
        <h2>Rockers Visa-kort, växlingskurser</h2>
        <p>Något gick fel, försök igen senare.</p>
      </div>
    );
  }

  const ecbGroup = {
    label: 'Europeiska centralbankens kurser',
    options: currencies.currencies,
  };

  return (
    <>
      <h1 className={styles.title}>Rockers Visa-kort, växlingskurser</h1>
      <div className={styles.container}>
        <SelectInput
          darkMode
          value={currency}
          options={[ecbGroup]}
          getOptionLabel={option => `${option.alpha3}: ${option.name}`}
          getOptionValue={option => option.alpha3}
          onChange={value => setCurrency(value)}
        />
        {!!ecbRate && (
          <div>
            <span className={styles.labelText}>
              Europeiska centralbankens kurs
            </span>
            <div>
              <span
                className={styles.valueText}
              >{`1 SEK = ${ecbRate.fxRateEcb?.toString().replace('.', ',')} ${
                ecbRate?.toMoneyWithEcbRate?.currency
              }`}</span>
            </div>
          </div>
        )}
        <div>
          <span className={styles.labelText}>Visas kurs</span>
          <div>
            <span
              className={styles.valueText}
            >{`1 SEK = ${ecbRate.fxRateVisa?.toString().replace('.', ',')} ${
              ecbRate?.toMoneyWithEcbRate?.currency
            }`}</span>
          </div>
        </div>
        <div>
          <span className={styles.labelText}>Rockers påslag</span>
          <p className={styles.valueText}>1,50 %</p>
        </div>
        {ecbRate && (
          <div>
            <span className={styles.labelText}>
              Totalt påslag jämfört med Europeiska Centralbankens kurs
            </span>
            <p className={styles.valueText}>
              {ecbRate.markupPercentageOverEcb?.toString().replace('.', ',')} %
            </p>
          </div>
        )}
      </div>
      <p className={styles.legalText}>
        Växlingskurser kan variera och kan även förändras under den tid som
        förlöper mellan att transaktionen genomförs och medel dras från ditt
        tillgängliga saldo. Du samtycker till att valutakursändringar kan
        tillämpas omgående och utan att du blir underrättad om det i förväg.
      </p>
    </>
  );
};

export default EcbRates;
