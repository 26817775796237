import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './selectInput.module.css';
import Select from 'react-select';
import classnames from 'classnames';

class SelectInput extends React.Component {
  handleChange = selectedOption => {
    const { onSelect, performValidation } = this.props;
    onSelect(selectedOption.value);
    performValidation(selectedOption.value);
  };

  render() {
    const {
      value,
      title,
      label,
      errorMessage,
      options,
      isValid,
      isSubmitted,
      controlRef,
      darkMode,
      ...otherProps
    } = this.props;

    const shouldDisplayError = isSubmitted && !isValid;
    const containerClass = classnames({
      [styles.selectInputContainer]: true,
      [styles.error]: shouldDisplayError,
      [styles.hasValue]: value,
      [styles.darkMode]: darkMode,
    });
    return (
      <div className={containerClass}>
        <p className={styles.title}>
          {shouldDisplayError ? errorMessage : title}
        </p>
        <Select
          value={value}
          onChange={this.handleChange}
          options={options}
          placeholder=""
          styles={customStyles}
          label={label || title}
          isSearchable={false}
          ref={controlRef}
          darkMode={darkMode}
          {...otherProps}
        />
      </div>
    );
  }
}
export default SelectInput;
SelectInput.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  isValid: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  errorMessage: PropTypes.string,
  performValidation: PropTypes.func,
  value: PropTypes.object,
  controlRef: PropTypes.object,
  darkMode: PropTypes.bool,
};

SelectInput.defaultProps = {};

/**
 * custom styles for the select menu
 * https://www.npmjs.com/package/react-select
 */
const customStyles = {
  container: () => ({
    border: 'none',
  }),

  control: (provided, state) => {
    const {
      selectProps: { darkMode },
    } = state;
    return {
      ...provided,
      width: '100%',
      borderWidth: 0,
      borderColor: 'transparent',
      boxShadow: 'none',
      height: 56,
      cursor: 'pointer',
      backgroundColor: !darkMode ? 'white' : '#1D1B2F',
    };
  },

  placeholder: provided => ({
    ...provided,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 600,
    display: 'none',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    '& svg': {
      color: !state.selectProps.darkMode ? '#332C6B' : '#fff',
    },
  }),

  indicatorSeparator: () => ({
    diplay: 'none',
  }),

  dropdownIndicator: (provided, state) => {
    const transform = state.selectProps.menuIsOpen
      ? 'scale(-1.4)'
      : 'scale(1.4)';

    const sty = {
      color: '#34a8ed',
      [`:hover`]: {},
    };
    return { ...provided, transform, ...sty };
  },

  menu: provided => ({
    ...provided,
    marginTop: 0,
    zIndex: 3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: '0 4px 11px hsl(0deg 0% 0% / 5%)',
    borderTop: '1px solid #eeeeee',
    marginLeft: -16,
  }),

  menuList: provided => ({
    ...provided,
    maxHeight: 450,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  option: (provided, state) => {
    return {
      color: state.isSelected ? '#494283' : '#939393',
      fontWeight: state.isSelected ? '600' : '400',
      height: 48,
      lineHeight: '48px',
      [`:hover`]: { backgroundColor: '#f7f4fc' },
      cursor: 'pointer',
      padding: '0 12px',
      userSelect: 'none',
      width: '100%',
    };
  },

  valueContainer: (provided, state) => {
    return {
      ...provided,
      marginTop: state.selectProps.label ? 16 : 4,
      paddingLeft: 0,
    };
  },

  singleValue: (provided, state) => ({
    ...provided,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    color: state.selectProps.darkMode ? '#F2F4FF' : '#494283',
  }),
};
