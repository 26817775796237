import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';
import TextHero from '@src/components/TextHero';
import EcbRates from '@src/components/hjalp-components/ecbRates';
import Container from '@src/components/Container';
import { ContentfulContactComponent } from '../../graphql-types';
import ContactMethods from '@src/components/ContactMethods';
import SupportCategories from '@src/components/SupportCategories/SupportCategories';

function Hjalp({ data }) {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;

  const headLine = data.contentfulPage.headLine;

  return (
    <Layout path="/hjalp">
      <SEO title={headLine} />
      <Container>
        <TextHero
          title={pageHeader}
          subtitle={pageHeaderDescription}
          style={{ marginBottom: 0, marginTop: 100 }}
        />
        <SupportCategories />
      </Container>
      <Container>
        <EcbRates />
      </Container>
    </Layout>
  );
}

export default Hjalp;

export const query = graphql`
  query Hjalp {
    contentfulPage(pageUrl: { eq: "/hjalp2.0" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContactHeader
      pageContent {
        ... on ContentfulFaq {
          header
          questionGroups {
            title
            contentfulid
            questions {
              ... on ContentfulQuestion {
                question
                answer {
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
`;
